<template>
    <div class="select">
        <p v-if="label" @click="toggleDropdown" class="select__label">{{ label }}</p>
        <div class="select__header" :class="{ error: !!error }" @click="toggleDropdown">
            <span>{{ selected ? selected.label : placeholder }}</span>
            <span class="select__arrow" :class="{ open: isOpen }">▼</span>
        </div>
        <p v-if="error" class="select__error">{{ error }}</p>
        <ul v-if="isOpen" class="select__list">
            <li v-for="option in options" :key="option.value" @click="selectOption(option)"
                :class="{ selected: option.value === selected?.value }" class="select__item">
                {{ option.label }}
            </li>
        </ul>
    </div>
</template>
  
<script>
export default {
    name: 'CommonSelect',
    props: {
        label: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: String || Number,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        error: {
            type: String,
        }
    },
    data() {
        return {
            isOpen: false,
            selected: null,
        };
    },
    mounted() {
        this.selected = this.options.find(
            (option) => option.value === this.modelValue
        ) ?? null;
        document.addEventListener("click", this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    },
    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectOption(option) {
            this.selected = option;
            this.isOpen = false;
            this.$emit("update:modelValue", option.value);
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
            }
        },
    },
    watch: {
        modelValue(newValue) {
            this.selected = this.options.find(
                (option) => option.value === newValue
            ) ?? null;
        },
    },
};
</script>