<template>
  <ProgressBar :stage="2" />
  <BookingTimer />
  <MainContainer class="step2__main" :show-content="!isLoading" :show-loader="true">
    <div class="step2__main__container">
      <h1>Bitte gebe hier deine Kundeninformationen an</h1>
      <div class="step2__form-container">
        <form class="form step2__form" @submit.prevent="onSubmit">
          <div class="step2__business">
            <label><input v-model="userData.isBusiness" type="radio" :value="false" />
              Privatkunde
            </label>
            <label><input v-model="userData.isBusiness" type="radio" :value="true" />
              Geschäftskunde
            </label>
          </div>
          <TextInput class="step2__margin" v-if="userData.isBusiness" v-model="userData.company" title="Firmenname"
            :error="errorMsg.company" />
          <div class="step2__names step2__margin">
            <TextInput v-model="userData.firstName" title="Vorname" :error="errorMsg.firstName" />
            <TextInput v-model="userData.lastName" title="Nachname" :error="errorMsg.lastName" />
          </div>
          <div class="step2__names step2__margin">
            <div class="step2__birth-date ">
              <p>Geburtsdatum</p>
              <Datepicker input-class-name="datepicker" v-model="userData.birthDate" :maxDate="maxDate" :flow="flow"
                :enableTimePicker="false" locale="de" format="EEEE dd/MM/yyyy" cancelText="Abbrechen" :formatLocale="de"
                auto-apply>
              </Datepicker>
              <p v-if="errorMsg.birthDate" class="step2__birth-date-error">{{ errorMsg.birthDate }}</p>
            </div>
            <TextInput v-if="!userData.isBusiness" v-model="userData.birthPlace" title="Geburtsort"
              :error="errorMsg.birthPlace" />
          </div>
          <div class="step2__names step2__margin" v-if="userData.isBusiness">
            <TextInput v-model="userData.companyRegistrationNumber" title="Handelsregisternummer"
              :error="errorMsg.companyRegistrationNumber" />
            <TextInput v-model="userData.districtCourt" title="Amtsgericht" :error="errorMsg.districtCourt" />
          </div>
          <TextInput class="step2__margin" v-model="userData.phone" title="Mobilnummer" :error="errorMsg.phone" />
          <div class="step2__names step2__margin">
            <TextInput v-model="userData.email" title="E-Mail" :error="errorMsg.email" />
            <TextInput v-model="userData.confirmEmail" title="E-Mail bestätigen" :error="errorMsg.confirmEmail" />
          </div>
          <div class="step2__tax-radio">
            <TooltipComponent text="Bist du vorsteuerabzugsberechtigt?"
              info-text="Vorsteuerabzugsberechtigt sind Sie, wenn Sie ein Unternehmen führen oder selbstständig sind, auf ihrer Rechnung Umsatzsteuer erheben und diese an das Finanzamt abführen." />
            <div class="step2__tax-radio-inner">
              <label><input v-model="userData.entitledToDeduction" type="radio" :value="false" />
                Nein
              </label>
              <label><input v-model="userData.entitledToDeduction" type="radio" :value="true" />
                Ja
              </label>
            </div>
            <p v-if="errorMsg.entitledToDeduction" class="step2__tax-radio-error">{{ errorMsg.entitledToDeduction }}</p>
          </div>
          <div class="step2__address">
            <h2 class="step2__form-subtitle">Adresse</h2>
            <TextInput class="step2__margin" v-model="userData.street" title="Straße und Hausnummer"
              :error="errorMsg.street" />
            <div class="step2__address-bottom step2__margin">
              <TextInput class="step2__city" v-model="userData.city" title="Stadt" :error="errorMsg.city" />
              <TextInput v-model="userData.zip" title="PLZ" :error="errorMsg.zip" />
            </div>
            <TextInput class="step2__margin" v-model="userData.country" title="Land" :error="errorMsg.country" />
          </div>
          <div class="step2__heard-about-us-section">
            <CommonSelect class="step2__heard-about-us" v-model="heardAboutUs"
              label="Wie hast du von uns erfahren?" :options="heardAboutUsOptions" :error="errorMsg.heardAboutUs" />
          </div>
          <div class="step2__agreement">
            <button class="step2__agreement-prime-button" @click="agreementDocuments('dataProtectionDeclaration')">
              <img class="step2__agreement-document-icon" :src="require('../assets/images/document.svg')">
              <p class="step2__agreement-document-label">Datenschutzhinweise</p>
            </button>
            <button class="step2__agreement-prime-button" @click="agreementDocuments('termsAndCondition')">
              <img class="step2__agreement-document-icon" :src="require('../assets/images/document.svg')">
              <p class="step2__agreement-document-label">AGB</p>
            </button>
          </div>
          <p class="step2__gdpr_information">Informationen zum Datenschutz findest du über den orangenen Button
            "Datenschutzhinweise"</p>
          <CommonCheckbox v-model="termsOfServiceAgreement" :error="errorMsg.termsOfServiceAgreement"><span> Hiermit
              bestätige ich, dass ich die AGB gelesen habe und akzeptiere.</span></CommonCheckbox>
        </form>
        <div class="step2__storage-card">
          <h2 class="step2__storage-card-title">Buchungsübersicht</h2>
          <p class="step2__storage-card-info">Ausgewähltes Lager:<span>{{ storage.name }}</span></p>
          <p class="step2__storage-card-info">B x T x H:<span>{{ storage.width }}m x {{ storage.depth }}m x {{
            storage.height
          }}m</span></p>
          <p class="step2__storage-card-info">Volumen:<span>{{ storage.volume }}m<sup>3</sup></span></p>
          <p class="step2__storage-card-info">Fläche:<span>{{ storage.area }}m<sup>2</sup></span></p>
          <p class="step2__storage-card-info">Stockwerk:<span>{{ storage.floor }}</span></p>
          <p v-if="storage.type === 'Garage'" class="step2__storage-card-info">Beheizt:<span>{{ isHeatedText }}</span>
          </p>
          <p v-if="storage.isEnergyConsumption && storage.type === 'Garage'" class="step2__storage-card-info">Inkl.
            Stromverbrauch/Jahr:<span>{{
              storage.energyConsumptionLimit }}kWh</span></p>
          <p class="step2__storage-card-consumptionInformation"
            v-if="storage.isEnergyConsumption && storage.type === 'Garage'">Zusätzlicher Stromverbrauch
            wird verbrauchsabhängig abgerechnet</p>
          <p class="step2__storage-card-info">Bezugstermin:<span>{{ startingDate }}</span></p>
          <p class="step2__storage-card-info">Einmalige Kaution:<span>{{ storage.priceDeposit }}€</span></p>
          <p v-if="!(userData.entitledToDeduction || storageType === 'Garage')" class="step2__storage-card-info">{{
            pricingPeriodLabelRent }} Miete:<span class="step2__storage-card-price">{{ price.toFixed(2) }}€</span></p>
          <p v-if="userData.entitledToDeduction || storageType === 'Garage'" class="step2__storage-card-info">{{
            pricingPeriodLabelRent }}
            Miete:<span>{{ price.toFixed(2) }}€</span></p>
          <p v-if="userData.entitledToDeduction || storageType === 'Garage'" class="step2__storage-card-info">
            Mehrwertsteuer
            ({{ storage.vat }}%):<span>{{ vat.toFixed(2) }}€</span></p>
          <p v-if="userData.entitledToDeduction || storageType === 'Garage'" class="step2__storage-card-info">{{
            pricingPeriodLabelTotalPrice }}
            Gesamtpreis:<span class="step2__storage-card-price">{{ totalPrice.toFixed(2) }}€</span></p>
        </div>
      </div>
    </div>
  </MainContainer>
  <FormButtons :leftButton="{ label: 'Zurück', icon: true, onClick: showBookingCancelModal }"
    :rightButton="{ label: 'Weiter zum Vertrag', icon: true, onClick: next, disabled: isLoading }" />
  <ConfirmBookingCancelModal v-if="cancelBookingModalVisbility" :storageName="storage.name"
    :closeModal="() => { this.cancelBookingModalVisbility = false }" :onBookingCancel="onBookingCancel" />
  <CustomModal :onClose="() => { this.showErrorModal = false }" title="Fehler" v-if="showErrorModal">
    <p style="margin: 2rem 0.5rem 0.5rem;">Bitte füllen Sie alle erforderlichen Felder aus, um fortzufahren.</p>
    <button class="steo2__fail-modal-button" @click="() => { this.showErrorModal = false }">Ich verstehe</button>
  </CustomModal>
</template>

<script>
import BookingTimer from "@/components/BookingTimer.vue";
import CommonCheckbox from "@/components/CommonCheckbox.vue";
import CommonSelect from "@/components/CommonSelect.vue";
import ConfirmBookingCancelModal from "@/components/ConfirmBookingCancelModal.vue";
import CustomModal from "@/components/CustomModal.vue";
import FormButtons from "@/components/FormButtons.vue";
import MainContainer from "@/components/MainContainer.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import TextInput from "@/components/TextInput.vue";
import TooltipComponent from "@/components/TooltipComponent.vue";
import { HEARD_ABOUT_US_OPTIONS, PRICING_PERIOD_TO_LABEL_MAP_RENT, PRICING_PERIOD_TO_LABEL_MAP_TOTAL_PRICE, STORAGE_BOOKING_STAGES } from "@/config/constants";
import { RouterPaths, getPathWithLocationName } from "@/router/index";
import store, { userDataInitialState } from "@/store";
import Datepicker from '@vuepic/vue-datepicker';
import { de } from 'date-fns/locale';
import moment from "moment";
import { onMounted, ref } from "vue";
import { calculatePrice, calculateVAT } from "../utils/price";

export default {
  name: 'Step2_Profile',
  components: {
    Datepicker,
    ProgressBar,
    FormButtons,
    TextInput,
    MainContainer,
    CommonCheckbox,
    TooltipComponent,
    BookingTimer,
    ConfirmBookingCancelModal,
    CustomModal,
    CommonSelect
  },
  setup() {
    const date = ref();
    const flow = ref(['year', 'month', 'calendar']);
    onMounted(() => {
      date.value = store.state.user.birthDate || new Date(new Date().setFullYear(new Date().getFullYear() - 19));
    })
    return {
      date,
      flow,
      de
    }
  },
  async mounted() {
    if (store.state.authId) {
      this.axios
        .get(process.env.VUE_APP_API_BASE_URL + '/user', { params: { authId: store.state.authId, locationId: store.state.locationId } })
        .then(res => {
          this.sfUserExists = true;
          const sfUser = res.data.userData;

          const isB2B = sfUser.type === 'B2B';
          this.userData = {
            ...this.userData, isBusiness: isB2B, company: isB2B ? sfUser.companyName : undefined, email: sfUser.email,
            firstName: sfUser.firstName, lastName: sfUser.lastName, entitledToDeduction: sfUser.entitledToDeduction,
            birthDate: moment(sfUser.birthdate, 'YYYY-MM-DD').toDate(), phone: sfUser.phone, street: sfUser.street, city: sfUser.city, country: sfUser.country,
            zip: sfUser.zip, birthPlace: sfUser.birthPlace, companyRegistrationNumber: sfUser.companyRegistrationNumber, districtCourt: sfUser.districtCourt
          }
        })
        .catch(err => {
          if (err.response.status !== 404) {
            this.$router.push(RouterPaths.ERROR);
          }
        })
        .finally(() => {
          this.isLoading = false;
        })
    } else {
      this.isLoading = false;
    }
  },
  data() {
    return {
      storage: store.state.storage,
      locationName: store.state.locationName,
      startingDate: store.state.date,
      pricingPeriod: store.state.pricingPeriod,
      storageType: store.state.storageType,
      pricingPeriodLabelRent: PRICING_PERIOD_TO_LABEL_MAP_RENT[store.state.pricingPeriod],
      pricingPeriodLabelTotalPrice: PRICING_PERIOD_TO_LABEL_MAP_TOTAL_PRICE[store.state.pricingPeriod],
      userData: userDataInitialState,
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      entitledToDeduction: store.state.entitledToDeduction,
      errorMsg: {},
      termsOfServiceAgreement: false,
      cancelBookingModalVisbility: false,
      isLoading: true,
      showErrorModal: false,
      heardAboutUs: store.state.heardAboutUs,
      heardAboutUsOptions: HEARD_ABOUT_US_OPTIONS
    };
  },
  computed: {
    isHeatedText() {
      return this.storage.heated ? 'ja' : 'nein';
    },
    price() {
      const priceMap = {
        week: this.storage.priceWeekly,
        month: this.storage.priceMonthly,
        year: this.storage.priceYearly
      }
      return priceMap[this.pricingPeriod];
    },
    vat() {
      return calculateVAT(this.storage.vat, this.price);
    },
    totalPrice() {
      return calculatePrice(this.price, this.storage.vat, true, 0)
    }
  },
  methods: {
    validate() {
      let isValid = true;
      this.errorMsg = {};
      if (!/^[\d\s\-+]{9,}$/.test(this.userData.phone)) { this.errorMsg['phone'] = "Bitte ausfüllen richtige Mobilnummer"; isValid = false; }
      if (!this.userData.firstName) { this.errorMsg['firstName'] = "Bitte ausfüllen"; isValid = false; }
      if (!this.userData.lastName) { this.errorMsg['lastName'] = "Bitte ausfüllen"; isValid = false; }
      if (!this.userData.phone) { this.errorMsg['phone'] = "Bitte ausfüllen"; isValid = false; }
      if (!this.userData.email) { this.errorMsg['email'] = "Bitte ausfüllen"; isValid = false; }
      if (this.userData.email !== null && !this.userData.email.includes('@')) { this.errorMsg['email'] = "Falscher Wert"; isValid = false; }
      if (!this.userData.confirmEmail) { this.errorMsg['confirmEmail'] = "Bitte ausfüllen"; isValid = false; }
      if (this.userData.confirmEmail !== null && !this.userData.confirmEmail.includes('@')) { this.errorMsg['confirmEmail'] = "Falscher Wert"; isValid = false; }
      if (!this.userData.street) { this.errorMsg['street'] = "Bitte ausfüllen"; isValid = false; }
      if (!this.userData.city) { this.errorMsg['city'] = "Bitte ausfüllen"; isValid = false; }
      if (!this.userData.zip) { this.errorMsg['zip'] = "Bitte ausfüllen"; isValid = false; }
      if (!this.userData.country) { this.errorMsg['country'] = "Bitte ausfüllen"; isValid = false; }
      if (!this.userData.birthDate) { this.errorMsg['birthDate'] = "Bitte ausfüllen"; isValid = false; }
      if (this.userData.isBusiness && !this.userData.company) { this.errorMsg['company'] = "Bitte ausfüllen"; isValid = false; }
      if (this.userData.entitledToDeduction === null) { this.errorMsg['entitledToDeduction'] = "Bitte auswählen"; isValid = false; }
      if (!this.termsOfServiceAgreement) { this.errorMsg['termsOfServiceAgreement'] = "Bitte über den orangenen Button oben das Dokument öffnen, lesen und anschließend mit dem Haken in der Box bestätigen"; isValid = false; }
      if (!this.userData.birthPlace && !this.userData.isBusiness) { this.errorMsg['birthPlace'] = "Bitte ausfüllen"; isValid = false; }
      if (!this.userData.companyRegistrationNumber && this.userData.isBusiness) { this.errorMsg['companyRegistrationNumber'] = "Bitte ausfüllen"; isValid = false; }
      if (!this.userData.districtCourt && this.userData.isBusiness) { this.errorMsg['districtCourt'] = "Bitte ausfüllen"; isValid = false; }
      if (this.userData.email !== this.userData.confirmEmail) { this.errorMsg['confirmEmail'] = "Die Werte sind unterschiedlich"; isValid = false; }
      if (!this.heardAboutUs) { this.errorMsg['heardAboutUs'] = "Bitte ausfüllen"; isValid = false; }
      return isValid;
    },
    filterDataBasedOnUserType() {
      if (!this.userData.isBusiness) {
        this.userData.companyRegistrationNumber = '';
        this.userData.districtCourt = '';
      } else {
        this.userData.birthPlace = '';
      }
    },
    agreementDocuments(type) {
      let agrementType = type === 'dataProtectionDeclaration' ? 'https://www.lagerwunder.com/terms/%20datenschutzhinweise' : 'https://www.lagerwunder.com/terms/agb';
      window.open(agrementType, '_blank');
    },
    showBookingCancelModal() {
      this.cancelBookingModalVisbility = true;
    },
    onBookingCancel() {
      store.actions.saveUser({ ...this.userData, birthDate: moment(this.userData.birthDate).format('YYYY-MM-DD') });
      store.actions.saveHeardAboutUs(null);
      store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.UNBEGAN);
      store.actions.setTimerStartDate(null);
      store.actions.saveStateLocally();
      this.cancelBookingModalVisbility = false;
      this.$router.push(getPathWithLocationName(RouterPaths.BOX_SELECTION, this.locationName));
    },
    next() {
      if (this.validate()) {
        this.filterDataBasedOnUserType();
        store.actions.saveUser({ ...this.userData, birthDate: moment(this.userData.birthDate).format('YYYY-MM-DD') });
        store.actions.saveHeardAboutUs(this.heardAboutUs);
        store.actions.setStorageBookingStage(STORAGE_BOOKING_STAGES.CONTRACT)
        store.actions.saveStateLocally();
        this.$router.push(getPathWithLocationName(RouterPaths.CONTRACT, this.locationName));
      } else {
        this.showErrorModal = true
      }
    }
  }
}
</script>
