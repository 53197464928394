import { reactive } from "vue";
import { encryptedStorage } from "./utils/storage";
import { STORAGE_BOOKING_STAGES } from "./config//constants"
import { checkObjectsCompatibility } from "./utils/object";

export const userDataInitialState = {
  firstName: null,
  lastName: null,
  birthDate: null,
  birthPlace: null,
  companyRegistrationNumber: null,
  districtCourt: null,
  street: null,
  city: null,
  zip: null,
  email: null,
  phone: null,
  isBusiness: false,
  company: null,
  entitledToDeduction: null,
  country: null,
  confirmEmail: null
}

const initialState = {
  storageBookingStage: STORAGE_BOOKING_STAGES.UNBEGAN,
  pricingPeriod: null,
  locationId: "",
  locationName: "",
  storageType: "",
  areaFrom: 0,
  areaTo: 0,
  date: null,
  storage: null,
  authId: null,
  sfBookingId: null,
  timerStartDate: null,
  user: userDataInitialState,
  heardAboutUs: null,
  demo: false,
};

const state = reactive(initialState);

export default {
  state: state,
  actions: {
    saveStorageSearch: (locationId, locationName, type, areaFrom, areaTo, date) => {
      state.locationId = locationId;
      state.locationName = locationName;
      state.storageType = type;
      state.areaFrom = areaFrom;
      state.areaTo = areaTo;
      state.date = date;
    },
    saveStorage: (value) => {
      state.storage = value;
    },
    savePricingPeriod: (value) => {
      state.pricingPeriod = value;
    },
    saveUserEmail: (value) => {
      state.user.email = value;
    },
    saveUser: (user) => {
      state.user = { ...user };
    },
    setStorageBookingStage: (value) => {
      state.storageBookingStage = value
    },
    setAuthId: (value) => {
      state.authId = value;
    },
    setDemo: (value) => {
      state.demo = value;
    },
    setSfBookingId: (value) => {
      state.sfBookingId = value;
    },
    setTimerStartDate: (value) => {
      state.timerStartDate = value;
    },
    saveHeardAboutUs: (value) => {
      state.heardAboutUs = value
    },
    saveStateLocally: () => {
      encryptedStorage.setItem("app_state", JSON.stringify(state));
      sessionStorage.setItem("demo", state.demo);
    },
    resetStore: () => {
      Object.assign(state, initialState)
      encryptedStorage.removeItem("app_state");
    },
    loadState: () => {
      const stateLoaded = encryptedStorage.getItem("app_state");
      const demo = sessionStorage.getItem("demo");
      if (demo) state.demo = demo === "true";

      if (stateLoaded && checkObjectsCompatibility(stateLoaded, initialState)) {
        state.pricingPeriod = stateLoaded.pricingPeriod;
        state.locationId = stateLoaded.locationId;
        state.locationName = stateLoaded.locationName;
        state.storageType = stateLoaded.storageType;
        state.areaFrom = stateLoaded.areaFrom;
        state.areaTo = stateLoaded.areaTo;
        state.date = stateLoaded.date;
        state.heardAboutUs = stateLoaded.heardAboutUs;
        state.storage = stateLoaded.storage;
        state.sfBookingId = stateLoaded.sfBookingId;
        state.authId = stateLoaded.authId;
        state.timerStartDate = stateLoaded.timerStartDate && new Date(stateLoaded.timerStartDate);
        state.storageBookingStage = stateLoaded.storageBookingStage;
        if (stateLoaded.user) state.user = stateLoaded.user;
      }
    },
  },
};
